import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"

const OurPeople = () => {
    return(
        <section className="py-20 lg:pb-44">

            <div className="container px-5 mx-auto">
                <h2 className="text-center">Our People</h2>
                <div className="grid items-center justify-center mt-12 lg:grid-cols-2 gap-14 lg:gap-0">

                    <div className="relative text-center">
                        <motion.div
                            initial={{ opacity: 0, x: -100 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 1 }}
                        >
                            <div className="py-20 bg-center bg-no-repeat bg-contain" style={{backgroundImage: `url('/images/bg-avatar-1.svg')`}}>
                                <StaticImage
                                    src="../images/jonathan-galloway.png"
                                    alt="Jonathan Galloway"
                                    placeholder="blurred"
                                    className="w-1/2 h-auto rounded-full"
                                />
                            </div>
                            <div className="absolute left-20 top-12">
                                <svg xmlns="http://www.w3.org/2000/svg" width="217.02" height="217.02" viewBox="0 0 217.02 217.02">
                                <g id="Group_157" data-name="Group 157" transform="translate(0.5 0.5)">
                                    <path id="Path_219" data-name="Path 219" d="M44.258,88.516a108.011,108.011,0,0,0,108.01-108.01A108.011,108.011,0,0,0,44.258-127.5,108.011,108.011,0,0,0-63.752-19.494,108.011,108.011,0,0,0,44.258,88.516Z" transform="translate(63.752 127.504)" fill="none" stroke="#ffa188" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                </g>
                                </svg>
                            </div>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 1, delay: 0.5 }}
                        >
                            <p className="font-bold text-1.5xl mb-0">Jonathan Galloway</p>
                            <p className="mb-0 font-bold uppercase text-accent-dark">Director & Co-Founder</p>
                        </motion.div>
                    </div>

                    <div className="relative text-center">
                        <motion.div
                            initial={{ opacity: 0, x: 100 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 1 }}
                        >
                            <div className="py-20 bg-center bg-no-repeat" style={{backgroundImage: `url('/images/bg-avatar-2.svg')`}}>
                                <StaticImage
                                    src="../images/david-maxwell.png"
                                    alt="David Maxwell"
                                    placeholder="blurred"
                                    className="w-1/2 h-auto rounded-full"
                                />
                            </div>
                            <div className="absolute top-12 right-20">
                                <svg xmlns="http://www.w3.org/2000/svg" width="217.02" height="217.02" viewBox="0 0 217.02 217.02">
                                <g id="Group_180" data-name="Group 180" transform="translate(0.5 0.5)">
                                    <path id="Path_219" data-name="Path 219" d="M44.258,88.516a108.011,108.011,0,0,0,108.01-108.01A108.011,108.011,0,0,0,44.258-127.5,108.011,108.011,0,0,0-63.752-19.494,108.011,108.011,0,0,0,44.258,88.516Z" transform="translate(63.752 127.504)" fill="none" stroke="#928df3" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                </g>
                                </svg>
                            </div>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 1, delay: 0.5 }}
                        >
                            <p className="font-bold text-1.5xl mb-0">David Maxwell</p>
                            <p className="mb-0 font-bold uppercase text-accent-alt">Director & Co-Founder</p>
                        </motion.div>
                    </div>

                </div>
            </div>
        
        </section>
    )
}

export default OurPeople