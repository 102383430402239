import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"

const Cards = () => {

    return(
        <div className="grid gap-10 mt-16 lg:grid-cols-3 lg:gap-14">

            <motion.div 
                className="rounded-2xl"
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 1 }}
            >
                <StaticImage className="relative after:bg-primary/40 after:absolute after:top-0 after:bottom-0 after:left-0 after:right-0 after:rounded-t-2xl" src="../images/laptop.png" alt="Laptop" placeholder="blurred" />
                <div className="pb-12 bg-white px-7 pt-7 rounded-b-2xl">
                    <div className="font-bold text-[1.375rem] text-primary pb-5">Reporting</div>
                    <p className="mb-0">Take control of data across your business through professionally designed, validated and automatable dashboard suites.</p>
                </div>
            </motion.div>

            <motion.div 
                className="rounded-2xl"
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 1 }}
            >
                <StaticImage className="relative after:bg-primary/40 after:absolute after:top-0 after:bottom-0 after:left-0 after:right-0 after:rounded-t-2xl" src="../images/train.png" alt="Train" placeholder="blurred" />
                <div className="pb-12 bg-white px-7 pt-7 rounded-b-2xl">
                    <div className="font-bold text-[1.375rem] text-primary pb-5">Analysis</div>
                    <p className="mb-0">Use interconnected datasets to create impactful insight across functions and customers groups driving performance.</p>
                </div>
            </motion.div>

            <motion.div
                className="rounded-2xl"
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 1 }}
            >
                <StaticImage className="relative after:bg-primary/40 after:absolute after:top-0 after:bottom-0 after:left-0 after:right-0 after:rounded-t-2xl" src="../images/whiteboard.png" alt="Two people looking at a whiteboard" placeholder="blurred" />
                <div className="pb-12 bg-white px-7 pt-7 rounded-b-2xl">
                    <div className="font-bold text-[1.375rem] text-primary pb-5">Predict</div>
                    <p className="mb-0">Take the best of your company knowledge and combine with cutting-edge AI to streamline activities and uncover new potential.</p>
                </div>
            </motion.div>

        </div>
    )
}

export default Cards