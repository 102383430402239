import React, { useState, useEffect } from 'react'
import Logo from "../assets/logo.svg"
import ScrollIcon from "../assets/scroll.svg";
import Shapes from "../assets/bg-header.svg";
import { motion } from "framer-motion"

const Header = () => {

	const [scroll, setScroll] = useState(false);

    const variantsH1 = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 200 },
    }

    const variantsScroll = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: -50 },
    }
    
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 100);
        });
    }, []);

    return(
        <header className="max-h-[800px] lg:max-h-screen lg:h-screen relative h-screen bg-gradient-to-b from-primary to-[#FF6441] lg:bg-gradient-to-r lg:from-[#BD5B48] lg:via-[#B97EB3] lg:to-[#928DF3] flex flex-col items-center justify-center font-body overflow-hidden lg:after:absolute lg:after:z-10 lg:after:top-0 lg:after:bottom-0 lg:after:left-0 lg:after:w-1/2 lg:after:bg-gradient-to-br lg:after:from-primary lg:after:via-transparent lg:after:to-transparent">
            <div className="hidden lg:block lg:absolute lg:top-0 lg:bottom-0 lg:left-0 lg:right-0 lg:z-20"><Shapes className="w-full h-full" /></div>
            <div className={`fixed top-0 left-0 right-0 z-50 py-6 lg:py-10 transition-colors duration-300 ease-in-out ${scroll ? 'bg-primary' : ''}`}>
                <div className="container flex items-center justify-center px-5 mx-auto lg:justify-between">
                    <Logo />
                    <div className="items-center hidden space-x-5 lg:flex">
                        <a href="mailto:contact@clickflow.co.uk" className="py-3 transition-colors duration-200 ease-in-out bg-white rounded-full px-7 hover:bg-accent hover:text-white">Enquire Now</a>
                        {/* <div className="flex items-center space-x-2.5">
                            <a href="https://twitter.com/click_flow"><Twitter /></a>
                            <a href="https://www.linkedin.com/in/jonathangalloway19/"><LinkedIn /></a>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="container relative z-30 flex flex-col items-center justify-center px-5 mx-auto">
                <motion.h1 
                    className="text-white lg:text-[4.688rem] max-w-xl text-center leading-none text-5xl mb-0"
                    initial="hidden"
                    animate="visible"
                    variants={variantsH1}
                    transition={{ duration: 1 }}
                >
                    Data insights driving business performance
                </motion.h1>
                <a href="mailto:contact@clickflow.co.uk" className="py-3 mt-6 transition-colors duration-200 ease-in-out bg-white rounded-full px-7 hover:bg-accent hover:text-white lg:hidden">Enquire Now</a>
            </div>
            <motion.div 
                className="container absolute left-0 right-0 z-30 flex flex-col items-center justify-center mx-auto space-y-6 text-lg text-center text-white bottom-12"
                initial="hidden"
                animate="visible"
                variants={variantsScroll}
                transition={{ duration: 1, delay: 1 }}
            >
                <ScrollIcon />
                <span>Scroll to find out more</span>
            </motion.div>
        </header>
    )
}

export default Header