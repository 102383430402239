import * as React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import EcoSystem from "../components/ecosystem"
import EcoSystemIcon from "../assets/ecosystem.svg"
import Cards from "../components/cards"
import { StaticImage } from "gatsby-plugin-image"
import IndustryExperience from "../components/industryExperience"
import OurPeople from "../components/ourPeople"
import Helmet from "react-helmet"
import { motion } from "framer-motion"

const IndexPage = () => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <meta charSet="utf-8" />
        <title>Clickflow</title>
        <meta name="description" content="Data insights driving business performance" />
      </Helmet>
      <Header />
      <main className="bg-white font-body">
        <section className="relative pt-20 pb-32 overflow-hidden lg:pt-52 lg:pb-52">
          <div className="max-w-5xl px-5 mx-auto ">
            <h2 className="mb-0 text-center">Connected Eco-System</h2>

            <div className="flex flex-col items-center justify-center pt-20 space-y-10 text-center lg:space-y-0 lg:items-start lg:flex-row lg:text-left">
            
              <motion.div
                className="flex-shrink-0 lg:order-2 lg:mx-28 lg:self-center"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 1 }}
              >
                <EcoSystemIcon/>
              </motion.div>

              <div className="flex flex-col space-y-10 lg:order-1 lg:space-y-24">
                <EcoSystem heading="Integrable" text="Highly integrable connectors supporting legacy technology" />
                <EcoSystem heading="Secure" text="Secure data repository for key business data." />
              </div>

              <div className="flex flex-col space-y-10 lg:order-3 lg:space-y-24">
                <EcoSystem heading="Auto Reporting" text="Automated reporting and built cross business self service analysis." />
                <EcoSystem heading="Single Truth" text="Represents the single version of truth for key business processes." />
              </div>
            </div>  

          </div>

          <div className="absolute -bottom-[68px] -right-[700px] lg:-right-80">
              <svg xmlns="http://www.w3.org/2000/svg" width="1618.43" height="135.693" viewBox="0 0 1618.43 135.693">
              <g id="Group_38" data-name="Group 38" transform="translate(69.163 -1532)">
                <path id="Path_35" data-name="Path 35" d="M163.7,33.859A33.822,33.822,0,0,1,129.84,67.669a33.853,33.853,0,0,0-33.811,33.859A33.835,33.835,0,1,1,62.171,67.669,33.822,33.822,0,0,0,96.029,33.859a33.835,33.835,0,1,1,67.669,0" transform="translate(644.074 1532.307)" fill="#f5f4f2"/>
                <path id="Path_38" data-name="Path 38" d="M203.1,48.029A33.863,33.863,0,0,0,169.245,14.17H-572.3a33.832,33.832,0,0,0-33.859,33.859A33.822,33.822,0,0,0-572.3,81.839h741.55A33.853,33.853,0,0,0,203.1,48.029" transform="translate(537 1585.806)" fill="#f5f4f2"/>
                <path id="Path_40" data-name="Path 40" d="M203.1,48.029A33.863,33.863,0,0,0,169.245,14.17H-572.3a33.832,33.832,0,0,0-33.859,33.859A33.822,33.822,0,0,0-572.3,81.839h741.55A33.853,33.853,0,0,0,203.1,48.029" transform="translate(1346.163 1517.83)" fill="#f5f4f2"/>
              </g>
            </svg>
          </div>
          
        </section>

        <section className="bg-[#F5F4F2] py-20 lg:pb-28">
          <div className="container px-5 mx-auto">
            <h2 className="mb-0 text-center">What we do</h2>
              <Cards />
          </div>
        </section>

        <section className="relative py-24 overflow-hidden bg-right-top bg-no-repeat lg:pt-52 lg:pb-44" style={{backgroundImage: `url('/images/bg-shapes.svg')`}}>

          <div className="absolute -top-[68px] -left-[700px] lg:-left-80">
            <svg xmlns="http://www.w3.org/2000/svg" width="1618.43" height="135.693" viewBox="0 0 1618.43 135.693">
              <g id="Group_39" data-name="Group 39" transform="translate(69.163 -1532)">
                <path id="Path_35" data-name="Path 35" d="M163.7,33.859A33.822,33.822,0,0,1,129.84,67.669a33.853,33.853,0,0,0-33.811,33.859A33.835,33.835,0,1,1,62.171,67.669,33.822,33.822,0,0,0,96.029,33.859a33.835,33.835,0,1,1,67.669,0" transform="translate(644.074 1532.307)" fill="#f5f4f2"/>
                <path id="Path_38" data-name="Path 38" d="M203.1,48.029A33.863,33.863,0,0,0,169.245,14.17H-572.3a33.832,33.832,0,0,0-33.859,33.859A33.822,33.822,0,0,0-572.3,81.839h741.55A33.853,33.853,0,0,0,203.1,48.029" transform="translate(537 1585.806)" fill="#f5f4f2"/>
                <path id="Path_40" data-name="Path 40" d="M203.1,48.029A33.863,33.863,0,0,0,169.245,14.17H-572.3a33.832,33.832,0,0,0-33.859,33.859A33.822,33.822,0,0,0-572.3,81.839h741.55A33.853,33.853,0,0,0,203.1,48.029" transform="translate(1346.163 1517.83)" fill="#f5f4f2"/>
              </g>
            </svg>
          </div>

          <div className="container px-5 mx-auto">
            <div className="grid lg:grid-cols-2">

              <motion.div 
                className="flex items-center lg:absolute lg:-right-64 lg:top-0 lg:bottom-0 lg:order-2"
                  initial={{ opacity: 0, x: 256 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 1 }}
              >
                <StaticImage 
                  src="../images/website-laptop.png"
                  alt="Laptop"
                  placeholder="blurred"
                  layout="constrained"
                  width={961}
                  height={679}
                />
              </motion.div>

              <div className="text-center lg:text-left lg:order-1">
                <motion.div
                  initial={{ opacity: 0, x: -100 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 1 }}
                >
                  <h2 className="text-primary lg:text-[4.688rem] font-bold leading-none tracking-tight mb-8 max-w-2xl lg:pr-8">A committed focus to business improvement</h2>
                  <p className="max-w-md mb-10 text-lg text-primary">Driving innovation and analysis across business functions using the data available within the DataHub.</p>
                </motion.div>
                <motion.a 
                  href="mailto:contact@clickflow.co.uk"
                  className="inline-block py-3 text-white transition-colors duration-200 ease-in-out rounded-full bg-accent-dark px-7 hover:bg-accent-light"
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 1, delay: 1 }}
                  >
                    Contact us
                </motion.a>
              </div>
            </div>

          </div>
        </section>

        <IndustryExperience />

        <OurPeople />

      </main>
      <Footer />
    </>
  )
}

export default IndexPage
