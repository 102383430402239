import React, { useState } from 'react';
import { StaticImage } from "gatsby-plugin-image"
import PlusIcon from "../assets/plus.svg"
import MinusIcon from "../assets/minus.svg"

const IndustryExperience = () => {

    const [isActive, setActive] = useState("");

    const handleToggle1 = () => {
        if (isActive === "box1") {
            setActive("");
        }
        else {
            setActive("box1");
        }
    };

    const handleToggle2 = () => {
        if (isActive === "box2") {
            setActive("");
        }
        else {
            setActive("box2");
        }
    };

    const handleToggle3 = () => {
        if (isActive === "box3") {
            setActive("");
        }
        else {
            setActive("box3");
        }
    };

    return(
        <section className="relative pt-20 overflow-hidden bg-primary">

            <div className="hidden xl:block absolute -top-[68px] -right-64">
                <svg xmlns="http://www.w3.org/2000/svg" width="1618.43" height="135.694" viewBox="0 0 1618.43 135.694">
                    <g id="Group_43" data-name="Group 43" transform="translate(0 0)">
                        <path id="Path_35" data-name="Path 35" d="M28.36,33.859A33.822,33.822,0,0,0,62.219,67.669a33.853,33.853,0,0,1,33.811,33.859,33.835,33.835,0,1,0,33.859-33.859A33.822,33.822,0,0,1,96.029,33.859a33.835,33.835,0,1,0-67.669,0" transform="translate(713.134 0.307)" fill="#fff"/>
                        <path id="Path_38" data-name="Path 38" d="M-606.163,48.029A33.863,33.863,0,0,1-572.3,14.17h741.55a33.835,33.835,0,1,1,0,67.669H-572.3a33.853,33.853,0,0,1-33.859-33.811" transform="translate(1415.327 53.806)" fill="#fff"/>
                        <path id="Path_40" data-name="Path 40" d="M-606.163,48.029A33.863,33.863,0,0,1-572.3,14.17h741.55a33.835,33.835,0,1,1,0,67.669H-572.3a33.853,33.853,0,0,1-33.859-33.811" transform="translate(606.163 -14.17)" fill="#fff"/>
                    </g>
                </svg>
            </div>


            <div className="px-5 mx-auto ">
                <h2 className="pb-20 mb-0 text-center text-white">Industry Experience</h2>
            </div>
            <div className="flex flex-col xl:flex-row">
                <div className={`xl:h-[800px] relative transition-all duration-500 ease-in-out ${isActive === "box1" ? 'xl:w-3/4' : 'xl:w-1/3'}`}>
                    <StaticImage
                        src="../images/machine.png"
                        alt="Manufacturing machine"
                        placeholder="blurred"
                        className={`object-cover w-full h-full pointer-events-none xl:after:top-0 xl:after:left-0 xl:after:bottom-0 xl:after:right-0 xl:after:z-10 xl:after:absolute ${isActive === "box1" ? '' : 'xl:after:bg-gradient-to-t xl:after:from-primary/70'}`}
                    />
                    <button className={`xl:absolute xl:z-20 xl:flex xl:items-center xl:justify-between xl:bottom-0 xl:left-0 xl:right-0 pt-12 pb-24 xl:py-12 w-full px-10 transition-colors duration-500 ${isActive === "box1" ? 'bg-primary xl:space-x-4' : ''} `} onClick={handleToggle1}>
                        <div className={`text-left xl:flex xl:items-center xl:flex-1 ${isActive === "box1" ? 'xl:space-x-14' : ''}`}>
                            <span className='text-4.5xl font-bold text-white inline-block mb-2 xl:mb-0'>Manufacturing</span>
                            <p className={`text-white text-xl text-left transition-opacity mb-0 ${isActive === "box1" ? 'xl:opacity-1 max-w-lg' : 'xl:opacity-0 xl:h-0 xl:w-0 '}`}>Process performance tracking and predictive maintenance for multi disciplined manufacturing facilities in OEM & Tier 1 supply.</p>
                        </div>
                        <span>{isActive === "box1" ? <MinusIcon className="hidden text-white xl:block" /> : <PlusIcon className="hidden text-white xl:block" /> }</span>
                    </button>
                </div>
                <div className={`xl:h-[800px] relative transition-all duration-500 ease-in-out ${isActive === "box2" ? 'xl:w-3/4' : 'xl:w-1/3'}`}>
                    <StaticImage
                        src="../images/retail.png"
                        alt="Retail store"
                        placeholder="blurred"
                        className={`object-cover w-full h-full pointer-events-none xl:after:top-0 xl:after:left-0 xl:after:bottom-0 xl:after:right-0 xl:after:z-10 xl:after:absolute ${isActive === "box2" ? '' : 'xl:after:bg-gradient-to-t xl:after:from-primary/70'}`}
                    />
                    <button className={`xl:absolute xl:z-20 xl:flex xl:items-center xl:justify-between xl:bottom-0 xl:left-0 xl:right-0 pt-12 pb-24 xl:py-12 px-10 xl:space-x-14 transition-colors duration-500 ${isActive === "box2" ? 'bg-primary xl:space-x-14' : ''} `} onClick={handleToggle2}>
                        <div className={`text-left xl:flex xl:items-center xl:flex-1 ${isActive === "box2" ? 'xl:space-x-14' : ''}`}>
                            <span className='text-4.5xl font-bold text-white inline-block mb-2 xl:mb-0'>Retail</span>
                            <p className={`text-white text-xl text-left transition-opacity mb-0 ${isActive === "box2" ? 'xl:opacity-1 max-w-lg' : 'xl:opacity-0 xl:h-0 xl:w-0'}`}>Commercially focused analytics suite for nationwide trading reporting and supply chain management for top 3 supermarket retail.</p>
                        </div>
                        <span>{isActive === "box2" ? <MinusIcon className="hidden text-white xl:block" /> : <PlusIcon className="hidden text-white xl:block" /> }</span>
                    </button>
                </div>
                <div className={`xl:h-[800px] relative transition-all duration-500 ease-in-out ${isActive === "box3" ? 'xl:w-3/4' : 'xl:w-1/3'}`}>
                    <StaticImage
                        src="../images/rail.png"
                        alt="Train on tracks"
                        placeholder="blurred"
                        className={`object-cover w-full h-full pointer-events-none xl:after:top-0 xl:after:left-0 xl:after:bottom-0 xl:after:right-0 xl:after:z-10 xl:after:absolute ${isActive === "box3" ? '' : 'xl:after:bg-gradient-to-t xl:after:from-primary/70'}`}
                    />
                    <button className={`xl:absolute xl:z-20 xl:flex xl:items-center xl:justify-between xl:bottom-0 xl:left-0 xl:right-0 pt-12 pb-24 xl:py-12 px-10 xl:space-x-14 transition-colors duration-500 ${isActive === "box3" ? 'bg-primary xl:space-x-14' : ''} `} onClick={handleToggle3}>
                        <div className={`text-left xl:flex xl:items-center xl:flex-1 ${isActive === "box3" ? 'xl:space-x-14' : ''}`}>
                            <span className='text-4.5xl font-bold text-white inline-block mb-2 xl:mb-0'>Rail</span>
                            <p className={`text-white text-xl text-left transition-opacity mb-0 ${isActive === "box3" ? 'xl:opacity-1 max-w-lg' : 'xl:opacity-0 xl:h-0 xl:w-0'}`}>Cross business process analysis capabilities focusing on operational efficiencies and customer experience for largest UK rail organisation.</p>
                        </div>
                        <span>{isActive === "box3" ? <MinusIcon className="hidden text-white xl:block" /> : <PlusIcon className="hidden text-white xl:block" /> }</span>
                    </button>
                </div>
            </div>
        </section>
    )
}

export default IndustryExperience