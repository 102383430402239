import * as React from "react"
import { motion } from "framer-motion"

const EcoSystem = ({ heading, text }) => {
    return(
        <div>
            <motion.div 
                className="border-accent border-solid border-2 rounded-full py-3 px-4 inline-block text-center font-bold text-lg mb-2.5 lg:mb-5"
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 1 }}
            >
                { heading }  
            </motion.div>
            <motion.p
                className="mb-0 text-lg lg:pl-4"
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 1, delay: 0.50 }}
            >
                { text }
            </motion.p>
        </div>
    )
}

export default EcoSystem